<template>
    <svg class="main-help" xmlns="http://www.w3.org/2000/svg" xmlns:svgjs="http://svgjs.com/svgjs"
        xmlns:xlink="http://www.w3.org/1999/xlink" width="30" height="30"><svg xmlns="http://www.w3.org/2000/svg" width="30"
            height="30" fill="none" viewBox="0 0 29 29">
            <path fill="#ff8066"
                d="M14.6757 2.69434C8.23473 2.69434 3.00903 7.92003 3.00903 14.361C3.00903 20.802 8.23473 26.0277 14.6757 26.0277C21.1167 26.0277 26.3424 20.802 26.3424 14.361C26.3424 7.92003 21.1167 2.69434 14.6757 2.69434ZM14.3111 21.1666C14.0708 21.1666 13.8358 21.0953 13.6359 20.9617C13.4361 20.8282 13.2803 20.6384 13.1883 20.4163C13.0964 20.1943 13.0723 19.9499 13.1192 19.7142C13.1661 19.4785 13.2818 19.2619 13.4518 19.0919C13.6217 18.922 13.8383 18.8062 14.074 18.7594C14.3098 18.7125 14.5541 18.7365 14.7762 18.8285C14.9982 18.9205 15.188 19.0763 15.3216 19.2761C15.4551 19.476 15.5264 19.7109 15.5264 19.9513C15.5264 20.2736 15.3984 20.5827 15.1704 20.8106C14.9425 21.0385 14.6334 21.1666 14.3111 21.1666ZM16.3431 14.9686C15.3581 15.6298 15.2226 16.2356 15.2226 16.7916C15.2226 17.0172 15.1329 17.2336 14.9734 17.3931C14.8139 17.5526 14.5975 17.6423 14.3719 17.6423C14.1463 17.6423 13.9299 17.5526 13.7703 17.3931C13.6108 17.2336 13.5212 17.0172 13.5212 16.7916C13.5212 15.4602 14.1337 14.4017 15.3939 13.5553C16.5655 12.769 17.2278 12.2707 17.2278 11.1752C17.2278 10.4302 16.8024 9.86448 15.922 9.44581C15.7148 9.34737 15.2536 9.25137 14.686 9.25805C13.9739 9.26717 13.4209 9.4373 12.995 9.78001C12.1917 10.4265 12.1236 11.1302 12.1236 11.1405C12.1182 11.2522 12.0909 11.3618 12.0432 11.4629C11.9954 11.5641 11.9283 11.6549 11.8455 11.73C11.7626 11.8052 11.6658 11.8634 11.5606 11.9011C11.4553 11.9389 11.3436 11.9556 11.2319 11.9502C11.1202 11.9448 11.0106 11.9175 10.9095 11.8697C10.8083 11.822 10.7176 11.7548 10.6424 11.672C10.5672 11.5892 10.5091 11.4924 10.4713 11.3872C10.4335 11.2819 10.4168 11.1702 10.4222 11.0585C10.4289 10.9108 10.5316 9.58071 11.9274 8.45779C12.651 7.87567 13.5716 7.57307 14.6617 7.5597C15.4334 7.55059 16.1583 7.68123 16.6499 7.91335C18.121 8.60909 18.9292 9.76908 18.9292 11.1752C18.9292 13.2308 17.5553 14.1538 16.3431 14.9686Z"
                class="colorD4D8E2 svgShape" />
        </svg></svg>
</template>
<script>
export default {

}
</script>
<style scoped>
.svgShape {
    fill: #3762a5 !important;
}
</style>