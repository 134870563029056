<template>
  <div class="scrollable">
    <div class="international-message-container">
      <div class="messages__navigation contains-message-buttons">
        <div class="main-buttons-container">
          <button
            class="wallet-balance"
            type="button"
            @click="$router.push('/wallet').catch(() => {})">
            {{ $t("dashboard.send_message.international_messages.wallet") }}
            <span>{{ $t("misc.currency") }} {{ walletBalance }}</span>
          </button>

          <button
            type="button"
            class="is-active is-help-button"
            @click="showInternationalHelpModal">
            <helpIcon></helpIcon>
            {{ $t("dashboard.send_message.international_messages.get_help") }}
          </button>
          <button
            type="button"
            class="__reporting__nav is-active create-template"
            @click="openMessageModal('template')">
            {{ $t("dashboard.send_message.message_templates.create_template") }}
          </button>
        </div>
        <!-- Send Message Button End -->
        <div class="tab-buttons-container">
          <button @click="navigateToTemplates">
            {{ $t("dashboard.send_message.message_templates.template") }}
          </button>
          <button @click="scheduleMessage">
            {{
              $t("dashboard.send_message.scheduled_messages.scheduled_message")
            }}
          </button>
          <button class="is-active-tab international-button">
            {{
              $t("dashboard.send_message.international_messages.international")
            }}
            <!-- <div class="is-new">New!</div> -->
          </button>
        </div>
      </div>
      <div class="international-messages-main">
        <div class="left-rates">
          <el-input
            style="margin-bottom: 5px"
            :placeholder="
              $t('dashboard.send_message.international_messages.search_rates')
            "
            size="small"
            class="search-bar"
            v-model="searchRates"></el-input>
          <el-table
            height="60vh"
            :data="internationalRates"
            v-loading="rateLoading">
            <el-table-column
              :label="
                $t('dashboard.send_message.international_messages.country')
              ">
              <el-table-column
                :label="
                  $t('dashboard.send_message.international_messages.name')
                ">
                <template slot-scope="scope">
                  {{
                    `${scope.row.country_name} (${scope.row.country_abbreviation})`
                  }}
                </template>
              </el-table-column>
              <el-table-column
                :label="
                  $t('dashboard.send_message.international_messages.code')
                "
                prop="country_code"
                width="58"></el-table-column>
            </el-table-column>
            <el-table-column
              :label="$t('dashboard.send_message.international_messages.rate')">
              <el-table-column
                :label="
                  $t(
                    'dashboard.send_message.international_messages.international'
                  )
                "
                prop="international_rate"
                width="100"></el-table-column>
              <el-table-column
                :label="
                  $t('dashboard.send_message.international_messages.local')
                "
                prop="local_rate"
                width="100"></el-table-column>
            </el-table-column>
          </el-table>
          <div class="col-md-12" style="text-align: center; margin-top: 2em">
            <el-pagination
              background
              layout="prev, pager, next"
              @current-change="handleCurrentChangeRates"
              :page-size="pageSizeRates"
              :total="totalRates">
            </el-pagination>
          </div>
        </div>
        <div class="right-approved-templates">
          <div class="search-balance">
            <div class="">
              <el-input
                style="margin-bottom: 5px"
                :placeholder="
                  $t(
                    'dashboard.send_message.international_messages.search_templates'
                  )
                "
                size="small"
                class="search-bar"
                v-model="searchTemplates"></el-input>
            </div>
          </div>
          <el-table
            height="60vh"
            :data="internationalTemplates"
            v-loading="templateLoading">
            <el-table-column
              :label="$t('dashboard.send_message.message_templates.template')">
              <el-table-column
                :label="$t('misc.table.message_title')"
                prop="title">
                <template slot-scope="scope">
                  <span class="notranslate">{{ scope.row.title }}</span>
                </template>
              </el-table-column>
              <el-table-column :label="$t('misc.table.content')" prop="body">
                <template slot-scope="scope">
                  <span class="notranslate">{{ scope.row.body }}</span>
                </template>
              </el-table-column>
              <el-table-column
                :label="
                  $t(
                    'dashboard.send_message.international_messages.selected_countries'
                  )
                "
                prop="selected_countries"></el-table-column>
              <el-table-column
                :label="
                  $t('dashboard.send_message.international_messages.status')
                ">
                <template slot-scope="scope">
                  <span v-if="scope.row.status == 1" style="color: #31cd54">{{
                    $t("misc.table.approved")
                  }}</span>
                  <span
                    v-else-if="scope.row.status == 0"
                    style="color: #f7921c"
                    >{{ $t("misc.table.pending") }}</span
                  >
                  <span v-else style="color: #f56c6c">{{
                    $t("misc.table.not_approved")
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column label="Sender ID">
                <template slot-scope="scope">
                  {{ scope.row.sender_id.sender_id }}
                </template>
              </el-table-column>
              <el-table-column label="Actions">
                <template slot-scope="scope">
                  <div class="actions-container">
                    <button
                      v-if="scope.row.status == 1"
                      @click="
                        openGeneralModals({
                          message: scope.row,
                          key: scope.$index,
                          type: 'send',
                        })
                      "
                      class="tb_btn __send action-button"
                      title="Send Message With Template">
                      <!-- <img width="20px" height="20px"
                                                        src="@/assets/Send.svg" alt=""> -->
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M19.4354 0.581983C18.9352 0.0685981 18.1949 -0.122663 17.5046 0.0786645L1.408 4.75952C0.679698 4.96186 0.163487 5.54269 0.0244302 6.28055C-0.117628 7.0315 0.378575 7.98479 1.02684 8.38342L6.0599 11.4768C6.57611 11.7939 7.24238 11.7144 7.66956 11.2835L13.4329 5.4843C13.723 5.18231 14.2032 5.18231 14.4934 5.4843C14.7835 5.77623 14.7835 6.24935 14.4934 6.55134L8.71999 12.3516C8.29181 12.7814 8.21178 13.4508 8.52691 13.9702L11.6022 19.0538C11.9623 19.6577 12.5826 20 13.2628 20C13.3429 20 13.4329 20 13.513 19.9899C14.2933 19.8893 14.9135 19.3558 15.1436 18.6008L19.9156 2.52479C20.1257 1.84028 19.9356 1.09537 19.4354 0.581983Z"
                          fill="#D4D8E2"
                          class="send-icon" />
                      </svg>
                    </button>
                    <button
                      class="tb_btn _delete action-button"
                      title="Delete Template">
                      <svg
                        width="19"
                        height="20"
                        viewBox="0 0 19 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        @click="
                          openGeneralModals({
                            message: scope.row,
                            key: scope.$index,
                            type: 'delete',
                          })
                        ">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M17.4185 3.30531C17.8087 3.30531 18.1335 3.62537 18.1335 4.03263V4.40918C18.1335 4.80653 17.8087 5.1365 17.4185 5.1365H0.796052C0.4049 5.1365 0.0800781 4.80653 0.0800781 4.40918V4.03263C0.0800781 3.62537 0.4049 3.30531 0.796052 3.30531H3.72042C4.31447 3.30531 4.83145 2.88813 4.96509 2.29954L5.11823 1.62374C5.35624 0.703186 6.13952 0.0917969 7.03595 0.0917969H11.1777C12.0643 0.0917969 12.8564 0.703186 13.0856 1.57518L13.2495 2.29855C13.3822 2.88813 13.8992 3.30531 14.4942 3.30531H17.4185ZM15.9329 17.0702C16.2382 14.259 16.7727 7.58027 16.7727 7.51289C16.7922 7.30877 16.7249 7.11554 16.5913 6.95997C16.4479 6.8143 16.2665 6.7281 16.0665 6.7281H2.15474C1.9538 6.7281 1.76262 6.8143 1.62996 6.95997C1.49535 7.11554 1.42902 7.30877 1.43877 7.51289C1.44056 7.52527 1.45974 7.76053 1.49181 8.15384C1.63426 9.90107 2.03102 14.7674 2.2874 17.0702C2.46884 18.7666 3.59547 19.8328 5.22738 19.8715C6.48668 19.9002 7.78402 19.9101 9.11062 19.9101C10.3602 19.9101 11.6292 19.9002 12.9275 19.8715C14.616 19.8427 15.7417 18.7953 15.9329 17.0702Z"
                          fill="#D4D8E2"
                          class="delete-icon" />
                      </svg>
                    </button>
                  </div>
                </template>
              </el-table-column>
            </el-table-column>
          </el-table>
          <div class="col-md-12" style="text-align: center; margin-top: 2em">
            <el-pagination
              background
              layout="prev, pager, next"
              @current-change="handleCurrentChangeTemplates"
              :page-size="pageSizeTemplates"
              :total="totalTemplates">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <messageModal
      :payload="dataPayload"
      :from="from"
      ref="messageModal"
      @saveTemplate="saveTemplate"
      @deleteSuccess="handleDeleteSucccess" />
    <el-dialog :visible.sync="showInternationalHelp" :width="dynamicModalWidth">
      <div class="modal-header">
        <h4 class="modal-title">International Messages</h4>
      </div>
      <div class="modal-body">
        <h4 class="note-international">
          {{
            $t(
              "dashboard.send_message.international_messages.note.noteInternational"
            )
          }}
        </h4>
        <p>{{ $t("dashboard.send_message.international_messages.note.beforeSendingMessages") }}</p>
        <ul>
          <li>
            {{ $t("dashboard.send_message.international_messages.note.requirements.createTemplate") }}
            <strong
              class="create-template-instructions"
              role="button"
              @click="openMessageModal('template')"
              aria-label="create a message template"
              title="create a message template">
              {{ $t("dashboard.send_message.international_messages.note.button.createTemplate") }}
            </strong>
            {{ $t("dashboard.send_message.international_messages.note.requirements.selectSenderID") }}
          </li>
          <li>
            {{ $t("dashboard.send_message.international_messages.note.requirements.approval") }}
          </li>
          <li>
            {{ $t("dashboard.send_message.international_messages.note.requirements.useSendButton") }}
            <strong style="color: #3762a5">
              "<img src="@/assets/Send.svg" alt="" />" {{ $t("misc.button.send") }}
            </strong>
          </li>
          <li>
            {{ $t("dashboard.send_message.international_messages.note.requirements.cannotEdit") }}
          </li>
          <li>
            {{ $t("dashboard.send_message.international_messages.note.requirements.chargedDirectly") }}
          </li>
        </ul>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-international"
          @click="showInternationalHelp = false">
          Close
        </button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import $ from "jquery";
  import store from "@/state/store.js";
  import messageModal from "./messageModal.vue";
  import helpIcon from "./helpIcon.vue";
  export default {
    components: { messageModal, helpIcon },
    data() {
      return {
        international_rates: [],
        international_templates: [],
        rateLoading: false,
        templateLoading: false,
        from: "",
        dataPayload: {
          currentModal: "",
          selectedKey: "",
          selectedMessage: {},
        },
        searchRates: "",
        searchTemplates: "",
        // pagination
        pageSizeRates: 10,
        totalRates: 0,
        currentPageRates: 1,
        pageSizeTemplates: 10,
        totalTemplates: 0,
        currentPageTemplates: 1,
        showInternationalHelp: false,
      };
    },
    methods: {
      navigateToTemplates() {
        this.$router.replace("/sms/messages").catch((err) => {
          if (err.name !== "NavigationDuplicated") throw err;
        });
      },
      scheduleMessage() {
        this.$router.push("/sms/scheduled_messages").catch((err) => {
          if (err.name !== "NavigationDuplicated") throw err;
        });
      },
      openMessageModal(type) {
        this.showInternationalHelp = false;
        if (type === "sendMessage") {
          this.from = "simpleMessage";
          this.$emit("SMSType", "simpleMessage");
          store.dispatch("uistate/addContactFormData", {
            title: "Send Message",
            type: "selectSenderId",
            fromMessage: true,
            whichType: "International",
          });
          // Set Scheduled Type to false
          store.dispatch("uistate/setFromScheduled", false);
        } else if (type === "template") {
          this.$emit("SMSType", "template");
          this.from = "template";
          store.dispatch("uistate/addContactFormData", {
            title: "Create a template",
            type: "template",
            fromTemplate: false,
          });
          store.dispatch("modal/updateCurrentPage", "template");
        }
        return this.showModal();
      },
      showModal() {
        let element = this.$refs.messageModal.$el;
        $(element).modal({
          backdrop: "static",
          keyboard: false,
          show: true,
        });
      },
      openGeneralModals({ message, key, type }) {
        if (type === "send") {
          this.dataPayload = {
            currentModal: "send",
            selectedKey: key,
            selectedMessage: message,
            goStraightToEdit: false,
          };
          store.dispatch("uistate/addContactFormData", {
            title: "",
            type: "view",
          });
          store.dispatch("modal/addFormData", {
            currentPage: "",
            nextPage: "",
            prevPage: "",
            formData: {
              id: message.id,
              body: message.body,
              title: message.title,
              type: message.type,
              isEdit: false,
              pageTitle: "",
            },
          });
        }
        if (type === "delete") {
          this.dataPayload = {
            currentModal: "delete",
            selectedKey: key,
            selectedMessage: message,
          };
          store.dispatch("uistate/addContactFormData", {
            title: "",
            type: "delete",
          });
        }
        return this.showModal();
      },
      handleDeleteSucccess() {
        this.refetchData();
      },
      saveTemplate() {
        this.refetchData();
      },
      refetchData() {
        this.rateLoading = true;
        this.templateLoading = true;
        store.dispatch("international/getRates").then((response) => {
          this.international_rates = response.data;
          this.rateLoading = false;
        });
        store
          .dispatch("campaigns/fetchTemplate")
          .then((res) => {
            this.international_templates = res.data.filter(
              (template) => template.type == 4
            );
            this.templateLoading = false;
          })
          .catch((err) => {});
      },
      handleCurrentChangeTemplates(val) {
        this.currentPageTemplates = val;
      },
      handleCurrentChangeRates(val) {
        this.currentPageRates = val;
      },
      showInternationalHelpModal() {
        this.showInternationalHelp = true;
      },
    },
    computed: {
      internationalRates() {
        // Filter by search
        if (this.searchRates) {
          return this.international_rates
            .filter(
              (rate) =>
                rate.country_name
                  .toLowerCase()
                  .includes(this.searchRates.toLowerCase()) ||
                rate.country_code
                  .toLowerCase()
                  .includes(this.searchRates.toLowerCase())
            )
            .slice(
              (this.currentPageRates - 1) * this.pageSizeRates,
              this.currentPageRates * this.pageSizeRates
            );
        }
        return this.international_rates.slice(
          (this.currentPageRates - 1) * this.pageSizeRates,
          this.currentPageRates * this.pageSizeRates
        );
      },
      internationalTemplates() {
        // Filter by search
        if (this.searchTemplates) {
          return this.international_templates
            .filter((template) =>
              template.title
                .toLowerCase()
                .includes(this.searchTemplates.toLowerCase())
            )
            .slice(
              (this.currentPageTemplates - 1) * this.pageSizeTemplates,
              this.currentPageTemplates * this.pageSizeTemplates
            );
        }
        return this.international_templates.slice(
          (this.currentPageTemplates - 1) * this.pageSizeTemplates,
          this.currentPageTemplates * this.pageSizeTemplates
        );
      },
      dynamicModalWidth() {
        if (screen.width < 768) {
          return "98%";
        } else {
          return "50%";
        }
      },
      walletBalance() {
        return store.getters["wallet/ballance"]
          ? store.getters["wallet/ballance"].wallet
          : 0;
      },
    },
    mounted() {
      this.rateLoading = true;
      this.templateLoading = true;
      this.showInternationalHelp = true;
      store.dispatch("international/getRates").then((response) => {
        this.international_rates = response.data;
        this.rateLoading = false;
        this.totalRates = this.international_rates.length;
      });
      store
        .dispatch("campaigns/fetchTemplate")
        .then((res) => {
          this.international_templates = res.data.filter(
            (template) => template.type == 4
          );
          this.templateLoading = false;
          this.totalTemplates = this.international_templates.length;
        })
        .catch((err) => {});
    },
  };
</script>
<style scoped>
  .international-messages-main {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 10px;
  }

  .messages__navigation button {
    width: 200px;
    height: 40px;
    border-radius: 44px;
    outline: none;
    background: #f3f3f4;
    border: 1px solid #d4d8e2;
    box-sizing: border-box;
    border-radius: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
    text-transform: capitalize;
    color: #484a4f;
    margin: 1em 0.5em;
  }

  .contains-message-buttons {
    display: flex;
    flex-direction: row-reverse !important;
    justify-content: space-between;
    width: 100%;
  }

  .create-template {
    border-radius: 25px !important;
    border-color: #f7921c !important;
    background-color: #f7921c !important;
    color: white !important;
    width: fit-content !important;
    padding-inline: 10px;
  }

  .is-active-tab {
    color: #f7921c !important;
    border: 1px solid #f7921c !important;
  }

  .international-button {
    width: fit-content !important;
  }

  .is-new {
    background-color: #f56c6c;
    color: white;
    border: none;
    padding: 2px;
    border-radius: 5px;
    animation: new 1s ease;
    display: inline-block !important;
  }

  .el-table,
  .el-table th,
  .el-table td {
    font-size: 12px;
    /* replace with your desired font size */
  }

  .warning {
    text-align: center;
  }

  .warning small {
    color: #f56c6c;
  }

  .__send:hover .send-icon {
    fill: #f7921c;
    transition: all 0.3s ease;
  }

  ._delete:hover .delete-icon {
    fill: red;
    transition: all 0.3s ease;
  }

  .action-button {
    border: none;
    background-color: transparent;
  }

  .actions-container {
    display: flex;
    gap: 10px;
  }

  .search-bar {
    display: flex;
    align-items: center;
    min-width: 50%;
    max-width: 65%;
  }

  .is-help-button svg {
    fill: #f7921c;
  }

  .note-international {
    color: #f56c6c;
  }

  .create-template-instructions {
    color: #f7921c;
  }

  .must-be-approved {
    color: #31cd54;
  }

  .search-balance {
    display: flex;
    width: 100%;
  }

  .search-balance div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .is-help-button {
    background-color: transparent !important;
    border: none !important;
    cursor: pointer;
    width: fit-content !important;
  }

  .btn-international {
    background-color: #3762a5;
    color: white;
  }

  .wallet-balance span {
    background-color: #31cd54 !important;
    padding: 0.3rem;
    border-radius: 20px !important;
    color: white !important;
    width: fit-content !important;
  }

  .wallet-balance {
    background-color: transparent !important;
    width: fit-content !important;
    border-color: transparent !important;
  }

  @keyframes new {
    30% {
      transform: scale(1.2);
    }

    40%,
    60% {
      transform: rotate(-20deg) scale(1.2);
    }

    50% {
      transform: rotate(20deg) scale(1.2);
    }

    70% {
      transform: rotate(0deg) scale(1.2);
    }

    100% {
      transform: scale(1);
    }
  }

  /* wrap table on smaller screens */
  @media screen and (max-width: 768px) {
    .international-messages-main {
      display: block;
    }

    .main-buttons-container {
      width: 100% !important;
    }

    .tab-buttons-container {
      width: 100% !important;
    }

    .tab-buttons-container button,
    .main-buttons-container button {
      width: 100% !important;
    }

    .contains-message-buttons {
      gap: 10px !important;
      flex-direction: row-reverse !important;
    }

    .wallet-balance span {
      display: block;
      margin: 0 auto;
    }
  }
</style>
